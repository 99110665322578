import React, { useState, useCallback, useRef } from 'react'
import { Checkbox, Dropdown, Menu, Select, Space, Table, Tooltip, Upload, UploadProps } from 'antd'
import type { ColumnsType } from 'antd/es/table'
import { ButtonCommon, IconWrap, InputCommon, PageHeader, RequireTag } from '../../components'
import meterHeader from '../../assets/images/meterHeader.png'
import clsx from 'clsx'
import styles from './index.module.scss'
import { UploadOutlined, RetweetOutlined } from '@ant-design/icons'
import { Alert, BuildingIcon, EditIcon, PrintIcon, SearchIcon, StaffIcon, TypeIcon } from '../../assets/icons'
import { MoreIcon } from '../../assets/icons'
import { ModalMeterDetail } from './DetailMeter'
import { meterUi } from '../../until/text/ui'
import { ModalQRMeter } from './QRMeter'
import { DataEmpty } from '../../components/DataEmpty'
import { useGetBuildingMeter, useImportMeter, usePrintMeter } from '../../hooks/query/meter'
import { Loading } from '../../components/Loading'
import { IBuilding, IMeter } from '../../models'
import { openNotificationWithIcon, useGetStaffSuggest, useGetSuggestionsBuildingAll } from '../../hooks'
import debounce from 'lodash.debounce'
import { meterMessage } from '../../until/text/messages'
import { PaginationCommon } from '../../components/Pagination'
import { useReactToPrint } from 'react-to-print'
import QRCode from 'qrcode.react'
import { SIZE_5MB, QR_CODE } from '../../until/constant'
import { RcFile } from 'antd/es/upload'
import type { CheckboxChangeEvent } from 'antd/es/checkbox'
import moment from 'moment'
import { useQuery } from 'react-query'
import { MeterApi } from '../../apis'
import { ConfirmRefetchQRCodeModal } from './ConfirmRefetchQRCodeModal'
import { CorrectionMeterModal } from './CorrectionMeterModal'

export const Meter = () => {
  const [openDropdown, setOpenDropdown] = useState<string>()
  const [visible, setVisible] = useState(false)
  const [visibleCorrection, setVisibleCorrection] = useState(false)
  const [meterModalQR, setMeterModalQR] = useState(false)
  const [isLoadingUpload, setIsLoadingUpload] = useState(false)
  const [refetchQRCode, setRefetchQRCode] = useState({
    visible: false,
    isRefetch: false
  })
  const [currentMeterSelected, setCurrentMeterSelected] = useState<IMeter>()
  const [indeterminate, setIndeterminate] = useState(false)
  const [checkAll, setCheckAll] = useState(false)
  const [showListQrCode, setShowListQrcode] = useState(false)
  const [params, setParams] = useState<any>({
    page: 1,
    page_size: 10,
  })
  const getMeterType = (type: string) => {
    switch (type) {
      case '1':
        return '水道'
      case '2':
        return '電気'
      case '3':
        return 'ガス'
    }
  }
  const { data, isLoading, isFetching, refetch } = useGetBuildingMeter(params)
  const { data: listBuilding } = useGetSuggestionsBuildingAll()
  const { data: listStaff } = useGetStaffSuggest({})
  const { isLoading: refetchQRCodeLoading, isFetching: refetchQRCodeFetching } = useQuery<any, Error>(['refetch_qrcodes', refetchQRCode.isRefetch], () => MeterApi.refetchQrCodes(),
    {
      enabled: refetchQRCode.isRefetch,
      onSuccess: () => {
        refetch()
        setRefetchQRCode({
          visible: false,
          isRefetch: false
        })
        openNotificationWithIcon('success', meterMessage.refetchSuccess)
      },
      onError: (error) => {
        setRefetchQRCode({
          visible: false,
          isRefetch: false
        })
        const errorMessage = error?.message
        openNotificationWithIcon('error', errorMessage)
      },
    }
  )
  const componentRef = useRef(null)
  const [checkedList, setCheckedList] = useState<IMeter[]>([])
  const mutateImportCsv = useImportMeter()
  const { mutateAsync: mutatePrintAsync, isLoading: isPrintLoading } = usePrintMeter()

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: `
      @page { size: A4 Portrait }
      @media print {
        body { 
          -webkit-print-color-adjust: exact; 
        }
      }
    `
  })

  const menu = (record: IMeter) => {
    let items = [
      {
        label: (
          <a
            onClick={() => {
              setMeterModalQR(true)
              setOpenDropdown('')
            }}
          >
            {meterUi.qrCode}
          </a>
        ),
        key: '0',
      },
      {
        label: (
          <a
            onClick={() => {
              setVisible(true)
              setOpenDropdown('')
            }}
          >
            {meterUi.showHistory}
          </a>
        ),
        key: '1',
      },
    ]
    const canEdit = record?.can_edit_latest_meter &&
      record?.last_scan_date !== null &&
      record?.reset_value === null

    if (canEdit) {
      items = [...items, {
        label: (
          <a
            onClick={() => {
              setVisibleCorrection(true)
              setOpenDropdown('')
            }}
          >
            {meterUi.correction}
          </a>
        ),
        key: '2',
      }]
    }

    return (
      <Menu
        items={items}
      />
    )
  }

  const columns: ColumnsType<IMeter> = [
    {
      title: `${meterUi.managementNo}`,
      dataIndex: 'management_association_no',
      key: 'management_association_no',
      width: 120,
    },
    {
      title: `${meterUi.buildingNo}`,
      dataIndex: 'building_code',
      key: 'building_code',
      width: 120,
    },
    {
      title: `${meterUi.roomNo}`,
      dataIndex: 'room_no',
      key: 'room_no',
      width: 120,
    },
    {
      title: `種類`,
      dataIndex: 'meter_type',
      key: 'meter_type',
      width: 60,
      render: (type: string) => typeMeter.find((el) => el.value == Number(type))?.name,
    },
    {
      title: `${meterUi.routeNumber}`,
      dataIndex: 'router_number',
      key: 'router_number',
      width: 110,
    },
    {
      title: `${meterUi.meterDigit}`,
      dataIndex: 'meter_digit',
      key: 'meter_digit',
      width: 110,
      render: (_, record) => {
        return (
          <div className={styles.page__content__alert}>
            <div className={styles.page__content__alert__number}>{record.meter_digit}</div>
            {record.abnormal && (
              <div className={styles.page__content__alert__icon}>
                <Alert />
              </div>
            )}
          </div>
        )
      },
    },
    {
      title: `${meterUi.decimalDigits}`,
      dataIndex: 'meter_digit_decimal',
      key: 'meter_digit_decimal',
      width: 110,
    },
    {
      title: `${meterUi.latestMeter}`,
      dataIndex: 'latest_meter',
      key: 'latest_meter',
      width: 150,
      render: (text, record) => {
        return (
          <div className={styles.page__content__alertEdit}>
            <div className={styles.page__content__alertEdit__number}>{text}</div>
            {record?.edit_flag && !record?.has_update && (
              <div className={styles.page__content__alertEdit__icon}>
                <EditIcon fill='#FF7A45' />
              </div>
            )}
          </div>
        )
      },
    },
    {
      title: meterUi.last_update,
      dataIndex: 'last_scan_date',
      key: 'last_scan_date',
      width: 120,
      render: (date: string) => date ? moment(date).format('YYYY/MM/DD') : '',
    },
    {
      title: meterUi.water_supply_basic_fee,
      dataIndex: 'water_supply_basic_fee',
      key: 'water_supply_basic_fee',
      width: 150,
      render: (text, record) => {
        return Number(record.meter_type) !== 1 ? '' : text
      }
    },
    {
      title: meterUi.water_sewage_basic_fee,
      dataIndex: 'water_sewage_basic_fee',
      key: 'water_sewage_basic_fee',
      width: 150,
      render: (text, record) => {
        return Number(record.meter_type) !== 1 ? '' : text
      }
    },
    {
      title: meterUi.basic_fee,
      dataIndex: 'basic_fee',
      key: 'basic_fee',
      width: 150,
      render: (text, record) => {
        return Number(record.meter_type) === 1 ? '' : text
      }
    },
    {
      title: '',
      dataIndex: 'showModalMeter',
      key: 'showModalMeter',
      fixed: 'right',
      align: 'center',
      width: 50,
      render: (_, record) => (
        <Space align="center" size={24}>
          <Dropdown
            className={styles.drop__down}
            placement="bottomRight"
            overlay={menu(record)}
            trigger={['click']}
            onVisibleChange={(visible: boolean) => {
              if (!visible) {
                setOpenDropdown('')
              }
            }}
          >
            <div
              className={clsx(
                {
                  [styles.isOpen]: openDropdown === record.id,
                },
                styles.threeDot,
              )}
              onClick={() => {
                setOpenDropdown(record.id)
                setCurrentMeterSelected(record)
              }}
            >
              <IconWrap icon={<MoreIcon />} size={18} />
            </div>
          </Dropdown>
        </Space>
      ),
    },
  ]

  const onCheckAllReset = () => {
    if (!checkAll) return
    setCheckedList([])
    setIndeterminate(false)
    setCheckAll(false)
  }

  const onChangePagination = (page: number, page_size: number) => {
    onCheckAllReset()
    if (page_size !== params.page_size) {
      return setParams({ ...params, page: 1, page_size })
    }
    setParams({ ...params, page, page_size })
  }

  const handleChangeSelectBuilding = (value: number) => {
    onCheckAllReset()
    setParams({ ...params, page: 1, building_id: value })
  }

  const handleChangeSelectStaff = (value: number) => {
    onCheckAllReset()
    setParams({ ...params, page: 1, user_id: value })
  }

  // eslint-disable-next-line no-unused-vars
  const handleChangeSelectType = (value: number) => {
    onCheckAllReset()
    setParams({ ...params, page: 1, type: value })
  }

  // eslint-disable-next-line no-unused-vars
  const typeMeter: { name: string; value: number }[] = [
    { name: meterUi.water, value: 1 },
    { name: meterUi.electric, value: 2 },
    { name: meterUi.gas, value: 3 },
  ]

  const onHandleSearch = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setCheckedList([])
    setIndeterminate(false)
    setCheckAll(false)
    debouncedSearch(e.target.value)
  }

  const onCheckAllChange = (e: CheckboxChangeEvent) => {
    const listMeter = data?.data.map((item: IMeter) => item)
    setCheckedList(e.target.checked && listMeter ? listMeter : [])
    setIndeterminate(false)
    setCheckAll(e.target.checked)
  }

  const debouncedSearch = useCallback(
    debounce((nextValue) => setParams((prev: any) => ({ ...prev, text_search: nextValue, page: 1 })), 500),
    [],
  )

  const props: UploadProps = {
    name: 'file',
    showUploadList: false,
    accept: 'text/csv',
    headers: {
      authorization: 'authorization-text',
    },
    beforeUpload(file: RcFile) {
      const isCSV = file.type === 'text/csv'
      const isLt5M = file.size < SIZE_5MB
      if (!isCSV) {
        openNotificationWithIcon('error', '', `${meterMessage.invalidCsv}\n${meterMessage.invalidCsv2}`)
        return isCSV
      }
      if (!isLt5M) {
        openNotificationWithIcon('error', '', meterMessage.csv5M)
        return isLt5M
      }
    },
    onChange(info) {
      if (info.file.status === 'done') {
        openNotificationWithIcon('success', '', `${info.file.name} ${meterMessage.upLoadSuccess}`)
      } else if (info.file.status === 'error') {
        openNotificationWithIcon('error', '', `${info.file.name} ${meterMessage.upLoadFail}`)
      }
    },
    customRequest: async (options: any) => {
      const { file } = options
      const fmData = new FormData()
      fmData.append('file', file)
      try {
        setIsLoadingUpload(true)
        const data = await mutateImportCsv.mutateAsync(fmData)
        openNotificationWithIcon('success', '', data.data)
      } catch (err: any) {
        openNotificationWithIcon('error', '', err.message)
      }
      setIsLoadingUpload(false)
    },
  }

  const rowSelection = {
    selectedRowKeys: checkedList.map((item) => item.id),
    onChange: (selectedRowKeys: React.Key[], selectedRows: IMeter[]) => {
      if (selectedRows.length > 0) {
        setIndeterminate(true)
      }
      if (selectedRows.length === data?.data.length) {
        setIndeterminate(false)
        setCheckAll(true)
      }
      if (selectedRows.length === 0) {
        setIndeterminate(false)
        setCheckAll(false)
      }
      setCheckedList(selectedRows)
    },
  }

  const handleConvertQrCode = async () => {
    await setShowListQrcode(true)
    await mutatePrintAsync()
    handlePrint()
  }

  const onRefetchQRCodes = () => {
    setRefetchQRCode({
      visible: true,
      isRefetch: false
    })
  }

  return (
    <div className={clsx(styles.meter)}>
      <PageHeader title={meterUi.meterlist} backgroundImage={meterHeader} height={'108px'}>
        <div className={styles.meter__header}>
          <InputCommon
            suffix={<IconWrap icon={<SearchIcon />} />}
            placeholder={meterUi.search}
            className={styles.meter__header__search__input}
            onChange={onHandleSearch}
          />
          <Upload {...props}>
            <ButtonCommon icon={<UploadOutlined />} className={styles.meter__header__button} type="primary">
              {meterUi.importCSV}
            </ButtonCommon>
          </Upload>
          <ButtonCommon
            disabled={!data?.data?.length}
            icon={<RetweetOutlined />}
            className={styles.meter__header__buttonRefetch}
            type="primary"
            loading={refetchQRCodeLoading || refetchQRCodeFetching}
            onClick={onRefetchQRCodes}
          >
            {meterUi.refetchQRCode}
          </ButtonCommon>
        </div>
      </PageHeader>
      <Loading isLoading={isLoadingUpload || isLoading || isFetching} />
      <div className={clsx(styles.page__content__filter)}>
        <div
          className={clsx(
            styles.group__filter,
            {
              [styles.hidden]: data?.data.length === 0 || isLoading || isFetching,
            },
            'select__group',
          )}
        >
          <Select
            disabled={listBuilding?.data.length === 0}
            className={styles.select__building}
            style={{ width: 160 }}
            optionLabelProp="label"
            allowClear
            placeholder={
              <>
                <IconWrap color="#8C8C8C" icon={<BuildingIcon />} className={clsx(styles.icon__filter)} />
                {meterUi.building}
              </>
            }
            onChange={handleChangeSelectBuilding}
            value={params.building}
          >
            {listBuilding?.data &&
              listBuilding.data.map((item: IBuilding) => (
                <Select.Option
                  key={item.id}
                  value={item.id}
                  label={
                    <React.Fragment>
                      <IconWrap color="#8C8C8C" icon={<BuildingIcon />} className={clsx(styles.icon__filter)} />
                      {`${item.name}`}
                    </React.Fragment>
                  }
                >
                  {`${item.name}`}
                </Select.Option>
              ))}
          </Select>
          <Select
            disabled={listStaff?.length === 0}
            style={{ width: 160 }}
            allowClear
            optionLabelProp="label"
            placeholder={
              <>
                <IconWrap
                  color="#8C8C8C"
                  icon={<StaffIcon />}
                  className={clsx(styles.icon__filter, styles.stafficon)}
                />
                {meterUi.staff}
              </>
            }
            onChange={handleChangeSelectStaff}
            value={params.staff}
          >
            {listStaff &&
              listStaff.map((item: any) => (
                <Select.Option
                  key={item.user_id}
                  value={item.user_id}
                  className="select__option"
                  label={
                    <React.Fragment>
                      <IconWrap color="#8C8C8C" icon={<StaffIcon />} className={clsx(styles.icon__filter)} />
                      <Tooltip title={item.username} placement="leftTop">
                        <span className={styles.text}>{item.username}</span>
                      </Tooltip>
                      {item.status === '0' ? <RequireTag message={meterUi.inactive} /> : null}
                    </React.Fragment>
                  }
                >
                  <Tooltip title={item.username} placement="leftTop">
                    <span className={styles.text}>{item.username}</span>
                  </Tooltip>
                  {item.status === '0' ? <RequireTag message={meterUi.inactive} /> : null}
                </Select.Option>
              ))}
          </Select>
          <Select
            // disabled={listStaff?.length === 0}
            style={{ width: 160 }}
            allowClear
            optionLabelProp="label"
            placeholder={
              <>
                <IconWrap color="#8C8C8C" icon={<TypeIcon />} className={clsx(styles.icon__filter, styles.stafficon)} />
                {meterUi.type}
              </>
            }
            onChange={handleChangeSelectType}
            value={params.type}
          >
            {typeMeter.map((item: { name: string; value: number }) => (
              <Select.Option
                key={item.value}
                value={item.value}
                className="select__option"
                label={
                  <React.Fragment>
                    <IconWrap color="#8C8C8C" icon={<TypeIcon />} className={clsx(styles.icon__filter)} />
                    {item.name}
                  </React.Fragment>
                }
              >
                <span className={styles.text}>{item.name}</span>
              </Select.Option>
            ))}
          </Select>
        </div>
        <div className={styles.printqrcode__btn__container}>
          <Checkbox
            indeterminate={indeterminate}
            onChange={onCheckAllChange}
            checked={checkAll}
            disabled={data?.data.length === 0}
          >
            {meterUi.checkAll}
          </Checkbox>
          <ButtonCommon
            // disabled={checkedList.length === 0}
            className={styles.printqrcode__btn}
            icon={<IconWrap icon={<PrintIcon />} size={18} />}
            type="ghost"
            onClick={handleConvertQrCode}
            disabled={checkedList.length === 0}
            loading={isPrintLoading}
          >
            {meterUi.printQRCode}
          </ButtonCommon>
        </div>
      </div>
      {!isLoading && (
        <div className={clsx(styles.page__content)}>
          {data?.data && data.data.length > 0 ? (
            <>
              <Table
                rowSelection={{
                  type: 'checkbox',
                  ...rowSelection,
                }}
                rowKey="id"
                columns={columns}
                dataSource={data.data}
                pagination={false}
                scroll={{ x: 700, y: 550 }}
              />
              {data.pagination.total > 10 ? (
                <div className={styles.pagination}>
                  <PaginationCommon
                    defaultCurrent={1}
                    total={data.pagination.total}
                    current={data.pagination.current_page}
                    pageSize={data.pagination.per_page}
                    onChangePagination={onChangePagination}
                    showSizeChanger
                    pageSizeOptions={['10', '20', '50', '100']}
                  />
                </div>
              ) : null}
            </>
          ) : (
            <DataEmpty detail={meterUi.noResultsMessage} className={styles.empty__results}>
              <Upload {...props}>
                <ButtonCommon icon={<UploadOutlined />} type="primary">
                  {meterUi.importCSV}
                </ButtonCommon>
              </Upload>
            </DataEmpty>
          )}
        </div>
      )}
      {visible && (
        <ModalMeterDetail
          meter={currentMeterSelected}
          title={meterUi.meterHistory}
          visible={visible}
          onOk={() => setVisible(false)}
          onCancel={() => setVisible(false)}
        />
      )}
      <ModalQRMeter
        title={meterUi.qrCode}
        data={currentMeterSelected}
        visible={meterModalQR}
        onCancel={() => setMeterModalQR(false)}
        onOk={() => setVisible(false)}
      />
      {showListQrCode && (
        <div className={styles.list__qrcode} ref={componentRef}>
          {checkedList &&
            checkedList.map((item: IMeter) => (
              <div key={item.id} className={styles.qrcode}>
                <QRCode
                  level={QR_CODE.LEVEL}
                  size={QR_CODE.SIZE}
                  id={`canvas${item.id}`}
                  value={item.qr_code}
                  renderAs="canvas"
                />
                <p>{`${item.management_association_no}-${item.building_code}-${item.room_no}-${getMeterType(item.meter_type)}`}</p>
              </div>
            ))}
        </div>
      )}
      <div className={styles.list__qrcode}>
        {currentMeterSelected && (
          <QRCode
            level={QR_CODE.LEVEL}
            size={QR_CODE.SIZE}
            id={`canvas-single${currentMeterSelected.id}`}
            value={currentMeterSelected.qr_code}
            renderAs="canvas"
          />
        )}
      </div>
      {refetchQRCode.visible && (
        <ConfirmRefetchQRCodeModal
          visible={refetchQRCode.visible}
          onCancel={() => setRefetchQRCode({
            visible: false,
            isRefetch: false
          })}
          loading={refetchQRCodeLoading || refetchQRCodeFetching}
          onOk={() => setRefetchQRCode({
            ...refetchQRCode,
            isRefetch: true,
          })}
        />
      )}
      {
        visibleCorrection && (
          <CorrectionMeterModal
            meter={currentMeterSelected}
            title={meterUi.correction}
            visible={visibleCorrection}
            onOk={() => {
              setVisibleCorrection(false)
              refetch()
            }}
            onCancel={() => setVisibleCorrection(false)}
          />
        )
      }
    </div>
  )
}
